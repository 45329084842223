<template>
    <div class="main-box">
       <div class="con-box">
            <div class="title">
                <p v-for="(tItem, ti) in tabLists" :key="ti" @click="onTab(tItem.id)">
                    <router-link :to="tItem.tabUrl" :class="{ 'active': tabIndex == tItem.id }">
                        {{tItem.val}}
                    </router-link>
                </p>
            </div>
            <router-view />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['orgId'])
    },
    data () {
        return {
            tabLists: [
                {
                    id: 1,
                    val: '信息发布',
                    tabUrl: '/InforManage/AddInfor'
                },
                {
                    id: 2,
                    val: '信息列表',
                    tabUrl: '/InforManage/InforList'
                }
            ],
            tabIndex: 1
        }
    },
    methods: {
        onTab (id) {
            this.tabIndex = id
        },
        getTabIndex () {
            for (let i in this.tabLists) {
                if (this.tabLists[i].tabUrl == this.$route.path) {
                    this.tabIndex = this.tabLists[i].id
                    break
                }
            }
        }
    },
    created () {
        this.getTabIndex()
    }
}
</script>
<style lang="less" scoped>
a {
    color: #000;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            align-items: center;
            p{
                margin-bottom: 0;
                width: 10%;
                text-align: center;
                cursor: pointer;
            }
            .active {
                border: 1px solid #8469C7;
                background-color: blue;
                color: #ffffff;
                padding: 5px 15px;
                background: -webkit-linear-gradient(to left,#3352c5,#6c4cbd); /* Safari 5.1-6.0 */
                background: -o-linear-gradient(to left,#3352c5,#6c4cbd); /* Opera 11.1-12.0 */ 
                background: -moz-linear-gradient(to left,#3352c5,#6c4cbd); /* Firefox 3.6-15 */
                background: linear-gradient(to left,#3352c5,#6c4cbd); /* 标准语法 */
            }
        }
    }
    .con-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>